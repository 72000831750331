<template>
  <div class="list">
    <div>
      <div class="head">
        <img src="../assets/error-icon.png" style="width: 48px" alt="" />
        <p>投保失败!</p>
      </div>
      <div class="msg">
        <div class="name">{{ productName }}</div>
        <div class="line"></div>
        <div class="message">
          <h3>失败原因</h3>
          <p class="reason">
            {{ $route.query.msg }}
          </p>
          <p class="call">
            请联系客服400******咨询是否投保成功，确认前请不要重复进行投保，防止进行重复支付，谢谢
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      productName: "预防接种异常反应个人短期意外伤害保险(基础版)",
      // productName: sessionStorage.getItem("productName"),
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.tips {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  padding: 56px 0;
  text-align: center;
}
.msg {
  width: 350px;
  height: 438px;
  margin: 0 auto;
  background: url("../assets/success-bg.png") no-repeat;
  background-size: 100%;
  margin-top: -64px;
  .name {
    width: 220px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 37px;
  }
  .line {
    width: 89%;
    margin: 0 auto;
    height: 2px;
    border-bottom: 2.5px dashed #ededed;
    margin-top: 21px;
  }
  .message {
    padding: 0 30px;
    padding-top: 19px;
    h3 {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 20px;
    }
    .reason {
      font-size: 13px;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      margin-top: 12px;
      text-align: justify;
    }
    .call {
      width: 289px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      margin-top: 129px;
      text-align: center;
    }
  }
}
.head {
  width: 100%;
  height: 183px;
  background: $primary-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 67px;
    margin-top: 16px;
  }
  p {
    font-size: 17px;
    font-weight: bold;
    color: #ffffff;
    line-height: 24px;
    margin-top: 16px;
  }
}
</style>